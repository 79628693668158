import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { appContext } from '../../context'
import { useNavigate } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { useFormik } from 'formik'
import Body from '../../components/Body'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import style from './Packages.module.scss'
import pageStyle from '../PageContent.module.scss'
import Button, { LinkButton } from '../../components/Button'
import PackageOption from '../../components/PackageOption'
import { FullPageLoader } from '../../components/Loader'
import parseJson from '../../utils/parseJson'
import URL from '../../constants/route-urls'
import useAddToBasket from '../../hooks/useAddToBasket'
import { Profile } from '../../components/icons'
import useDataLayer from '../../hooks/useDataLayer'
import NotificationCard from '../../components/NotificationCard'

const Packages = () => {
  let { urlGymId, seniority } = useParams()
  const context = useContext(appContext)
  const navigate = useNavigate()
  const { dataLoadedEvent } = useDataLayer()
  const [formIsValid, setFormIsValid] = useState(false)
  const [showSubmitError, setShowSubmitError] = useState(false)
  const [catalogErrorResponse, setCatalogErrorResponse] = useState<number | undefined>(undefined)

  const {
    response,
    loading,
    error: fetchError,
    fetchData: fetchPackageData,
  } = useFetch({
    method: 'get',
    url: `/catalog/product/${urlGymId}-${seniority}`,
  })

  const validateForm = (values: PackageFormValues) => {
    const errors: GenericObject = {}

    if (!values?.package) {
      errors.package = 'Required'
    }

    if (Object.keys(errors).length > 0) {
      // Form not valid ❌
      setFormIsValid(false) // Set footer button to disabled
    } else {
      // Form is valid 🥳
      setFormIsValid(true) // Set footer button to active

      const selectedOptionData = parseJson(values?.package)
      context?.setCurrentSelectionPrice(selectedOptionData?.price?.discountedPrice || selectedOptionData?.price?.totalPrice || 0)
    }

    return errors
  }

  // Data for add to cart service
  const packageProductData = {
    'product_type': 'SessionPackage',
    'productKey': `${urlGymId}-${seniority}`,
    'variantId': context?.packageFormValue?.package?.variantId,
    'quantity': 1,
    'personalTrainer': `${context?.selectedPersonalTrainer?.firstName} ${context?.selectedPersonalTrainer?.lastName}`,
    'location': context?.selectedGymData?.name || context?.gymId,
  }

  // Initialise the hook to add to basket. 
  const {
    addToCartResp,
    error,
    loading: addingToCart,
    postToBasket,
  } = useAddToBasket(packageProductData)

  useEffect(() => {
    // If no personal trainer has been selected, redirect to select PT step
    if (!context?.selectedPersonalTrainer) {
      navigate(`/${URL.SELECT_PERSONAL_TRAINER}`)
      return
    }

    context?.setGymId(urlGymId) // Update user's selected gym with gym ID in URL

    context?.setPtSeniority(seniority)
    validateForm(context?.packageFormValue)
    fetchPackageData()
  }, [])

  useEffect(() => {
    if(addToCartResp?.status === 200) {
      // navigate to next step on success
      navigate(`/${URL.CART}`)
      context?.setCurrentSelectionPrice(0)
      context?.setPackageFormValue(undefined)
    }
  }, [addToCartResp])

  useEffect(() => {
    const catalogFetchError: GenericObject = fetchError as GenericObject

    if (catalogFetchError?.response?.status === 404) {
      setCatalogErrorResponse(catalogFetchError?.response?.status)
      return
    }

    if (catalogFetchError) {
      setCatalogErrorResponse(catalogFetchError?.response?.status)
    }
  }, [response, fetchError])

  // Whenever a package is selected, remove the submit error message
  useEffect(() => {
    setShowSubmitError(false)
  }, [context?.packageFormValue])

  useEffect(() => {
    if (!context?.basket || !context?.selectedPersonalTrainer) {
      return
    }

    // Datalayer
    dataLoadedEvent({
      pageType: 'Packages',
      pageName: 'Packages',
      pageUrl: `${window.location.origin}/${URL.PACKAGES}`,
      pageTitle: 'Packages',
    },
    {
      'action': 'select package',
      'step': 3,
    }, [{
      'serviceLine': 'Personal Trainer',
      'location': context?.selectedGymData?.name ?? '',
      'clubid': context?.selectedGymData?.salesForceId ?? '',
      'personalTrainerId': `${context?.selectedPersonalTrainer.firstName} ${context?.selectedPersonalTrainer.lastName}`,
    }])
  }, [context?.basket])

  const formik = useFormik({
    initialValues: {
      package: context?.packageFormValue?.package,
    },
    onSubmit: (values) => {
      postToBasket()
    },
    validate: validateForm,
  })

  const loginURL = `${process.env.REACT_APP_LOGIN_URL}?returnPath=${window.location.href}`

  return (
    <>
      {/* Head */}
      <Helmet>
        <title>Packages</title>
      </Helmet>
      <Header
        title="Select a package"
        step={2}
      />
      <Body>
        <>
          <section aria-label='Packages introduction section' style={{ position: 'relative' }} className={`${pageStyle.pageSection} ${pageStyle.introText}`}>
            <h1 data-testid='packages-step-heading'>
              Packages
              {context?.selectedPersonalTrainer &&
                <> with {context?.selectedPersonalTrainer.firstName} {context?.selectedPersonalTrainer.lastName}</>
              }
            </h1>
            <div className={style.changePtWrapper}>
              <LinkButton
                buttonStyleType='text'
                className={style.changePtBtn}
                link={`/select-personal-trainer/${context?.gymId}`}
              >
                <Profile width={24} height={24} className={style.changePtIcon} />
                Change Personal Trainer
              </LinkButton>
            </div>
            <div>
              <p>All personal training packs can be used as <strong>30</strong> or <strong>60 minute sessions</strong>. Please arrange this directly with your personal trainer. Sessions are valid for 90 days after purchase.</p>
            </div>
          </section>

          {response?.data &&
            <section
              className={pageStyle.pageSection}
              data-testid='package-option-container'
              aria-label='Package selection section'
            >
              <form onSubmit={(e) => {e.preventDefault()}} id='packageSelection'>
                {response
                  ?.data
                  ?.variants
                  .sort((a: IPackageVariantServiceData, b: IPackageVariantServiceData) => {
                    // Sort largest to smallest session package
                    if (b.size && a.size) {
                      return a.size - b.size
                    }
                    return ''
                  })
                  .map((option: IPackageVariantServiceData, i: number) => {
                    return (
                      <PackageOption
                        key={i}
                        value={option}
                        checked={context?.packageFormValue?.package?.variantId === option.variantId}
                        size={option.size}
                        onChange={(e) => {
                          // Format package data
                          const optionValue = {
                            package: {
                              ...option,
                            },
                          }

                          // Set selected package in context. (Later used to add to cart)
                          context?.setPackageFormValue(optionValue)

                          // Trigger change handler
                          formik.handleChange(e)
                        }}
                        price={option.price.discountedPrice || option.price.totalPrice}
                        wasPrice={option.price.discountedPrice ? option.price.totalPrice : null}
                        offPercentage={option.price.discountPercentage}
                        inputTestId={'package-option-input'}
                        details={option.description}
                      />
                    )
                  })
                }

                {error && error.response?.status === 401 &&
                  <div className={style.errorWrapper}>
                    <NotificationCard
                      heading="Your session has expired due to inactivity."
                      type='danger'
                    >
                      <div>
                        <p>Please log in to continue buying personal training. Don’t worry, your basket items have been saved.</p>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <p><a href={loginURL}>Login</a></p>
                      </div>
                    </NotificationCard>
                  </div>
                }

                {error && error.response?.status !== 401 && (
                  <div className={style.errorWrapper}>
                    <NotificationCard
                      heading="We're sorry, but you cannot add packages to your basket right now"
                      type='danger'
                    >
                      Please try again later or contact your local gym to book personal training.
                    </NotificationCard>
                  </div>
                )}

                <div className={style.submitWrapper}>
                  <Button
                    onClick={() => {
                      if (!formIsValid) {
                        setShowSubmitError(true)
                        return
                      }
                      formik.submitForm()
                    }}
                    styleType={formIsValid ? 'primary' : 'disabled'}
                    testId='footer-next-button'
                    type='submit'
                  >
                    Select this package
                  </Button>
                  {showSubmitError && <p className={style.selectionErrorMsg}>Please select a package above to continue</p>}
                </div>
              </form>
            </section>
          }

          {/* No products found */}
          {(catalogErrorResponse === 404 || response?.data?.variants.length === 0) &&
            <section className={pageStyle.pageSection}>
              <NotificationCard
                heading='No packages found'
                type='danger'
              >
                We've experienced some technical difficulties and cannot display any packages. Please try again later or contact your local gym to book personal training.
              </NotificationCard>
            </section>
          }

          {/* API service failure */}
          {(catalogErrorResponse && catalogErrorResponse !== 404) &&
            <section className={pageStyle.pageSection}>
              <NotificationCard
                heading='No packages found'
                type='danger'
              >
                We've experienced some technical difficulties and cannot display any packages. Please try again later or contact your local gym to book personal training.
              </NotificationCard>
            </section>
          }
        </>
      </Body>

      <FullPageLoader isLoading={loading || addingToCart} />
    </>
  )
}

export default Packages