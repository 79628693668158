import { useContext, useState } from 'react'
import axios from 'axios'
import { appContext } from '../context'
import { getUserToken } from '../utils/getUserData'
import { TypeActiveMethod } from '../routes/Pay/types'

interface ITransactions {
  amount: {
    value: number;
    currency: string;
  };
  type: 'AUTHORIZATION' | 'CANCEL_AUTHORIZATION' | 'CHARGE' | 'REFUND' | 'CHARGEBACK';
  state: 'INITIAL' | 'PENDING' | 'SUCCESS' | 'FAILURE';
}

interface IPriceAmountObj {
  value: number;
  currency: string;
}

interface IGetPaymentResponse {
  paymentId: string,
  amountPlanned: IPriceAmountObj,
  amountPaid: IPriceAmountObj,
  paymentType: TypeActiveMethod;
  transactions: ITransactions[],
  sessionDetails: {
    id: string;
    sessionData: string;
  }
}

const userToken = getUserToken()

const usePayments = () => {
  const context = useContext(appContext)
  const [paymentLoading, setPaymentLoading] = useState(false)

  const getPayment = async (basketData?: BasketType) => {
    // Allow basketData param to override basket data from global state
    const existingPaymentId = basketData?.paymentInfo?.paymentInfoId ?? context?.basket?.paymentInfo?.paymentInfoId

    if (!existingPaymentId) {
      return undefined
    }

    try {
      const getResponse = await axios.request<IGetPaymentResponse>({
        method: 'GET',
        url: `${process.env.REACT_APP_APIM_URL}/payment/payment/${existingPaymentId}`,
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_SUB_KEY}`,
        },
      })

      return getResponse.data
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  // Set up adyen session
  const createPayment = async (basketData?: BasketType) => {
    // Allow basketData param to override basket data from global state
    const basket = basketData ?? context?.basket

    try {
      setPaymentLoading(true)
      const response = await axios.request({
        method: 'POST',
        url: `${process.env.REACT_APP_APIM_URL}/payment/payment`,
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_SUB_KEY}`,
        },
        data: {
          'basketId': basket?.basketId,
          'amountPlanned': {
            'value': basket?.price?.discountedPrice ?? basket?.price?.totalPrice,
            'currency': 'GBP',
          },
        },
      })

      setPaymentLoading(false)
      return response.data
    } catch (error) {
      // Handle errors
      console.error(error)
      setPaymentLoading(false)
      return error
    }
  }

  return {
    getPayment,
    paymentLoading,
    createPayment,
  }
}

export default usePayments
