import { useState } from 'react'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { loadingTimeout } from '../constants/timers'

const useFetch = (axiosParams: AxiosRequestConfig, baseUrl?: string) => {
  const [response, setResponse] = useState<AxiosResponse>()
  const [error, setError] = useState<AxiosError | any >()
  const [loading, setLoading] = useState(false)
  axios.defaults.baseURL = baseUrl || process.env.REACT_APP_APIM_URL
  axios.defaults.timeout = loadingTimeout // service calls will timeout after 30 seconds

  const fetchDataMethod = async (params: AxiosRequestConfig) => {
    setLoading(true)
    
    if (params.headers) {
      params.headers['Ocp-Apim-Subscription-Key'] = `${process.env.REACT_APP_APIM_SUB_KEY}`
    } else {
      params.headers = {
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_SUB_KEY}`,
      }
    }

    try {
      const result = await axios.request(params)
      setResponse(result)
      setError(null) // Reset error state when success
      return result
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchData = async (overrideParams?: AxiosRequestConfig) => {
    let params = axiosParams

    // Axios params can be overridden on a per function basis
    if (overrideParams) {
      params = {
        ...axiosParams,
        ...overrideParams,
      }
    }
    return await fetchDataMethod(params)
  }

  return {
    response,
    setResponse,
    error,
    setError,
    loading,
    fetchData,
  }
}

export default useFetch
